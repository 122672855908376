import Prismic from "prismic-javascript";

const API_ENDPOINT = "https://phonecompanion.cdn.prismic.io/api/v2";

export const getHomepage = lang => {
  return Prismic.getApi(API_ENDPOINT).then(api => {
    return api.query(Prismic.Predicates.at("document.type", "homepage"), {
      lang
    });
  });
};

export const getContactPage = lang => {
  return Prismic.getApi(API_ENDPOINT).then(api => {
    return api.query(Prismic.Predicates.at("document.type", "contact_page"), {
      lang
    });
  });
};

export const getLegalPage = lang => {
  return Prismic.getApi(API_ENDPOINT).then(api => {
    return api.query(Prismic.Predicates.at("document.type", "privacy_policy"), {
      lang
    });
  });
};

export const getFooter = lang => {
  return Prismic.getApi(API_ENDPOINT).then(api => {
    return api.query(Prismic.Predicates.at("document.type", "footer"), {
      lang
    });
  });
};

export const getDocumentationMenu = lang => {
  return Prismic.getApi(API_ENDPOINT).then(api => {
    return api.query(Prismic.Predicates.at("document.type", "menu"), { lang });
  });
};

export const getDocumentationPages = lang => {
  return Prismic.getApi(API_ENDPOINT).then(api => {
    return api.query(
      Prismic.Predicates.at("document.type", "documentation_page"),
      { lang }
    );
  });
};

export const getDocumentationPage = (lang, pageSlug, fallbackMenuPos) => {
  if (fallbackMenuPos) {
    return Prismic.getApi(API_ENDPOINT).then(api => {
      return api.query(
        Prismic.Predicates.at(
          "my.documentation_page. page_position_in_the_menu",
          -1
        ),
        { lang }
      );
    });
  } else {
    return Prismic.getApi(API_ENDPOINT).then(api => {
      return api.query(
        Prismic.Predicates.at("my.documentation_page.uid", pageSlug),
        { lang }
      );
    });
  }
};
