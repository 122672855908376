import React, { Component } from "react";
import "./styles.css";

export class Button extends Component {
  static propTypes = {};
  static defaultProps = { openInNewTab: true };

  render() {
    const { url, text, hidden, onClick, primary, openInNewTab } = this.props;

    const newTab = openInNewTab
      ? { target: "_blank", rel: "noreferrer noopener" }
      : null;

    let classes = "button";
    classes += hidden ? " hidden" : " shown";
    classes += primary ? " primary" : "";

    return (
      <a onClick={onClick} className={classes} href={url} {...newTab}>
        {text}
      </a>
    );
  }
}

Button.defaultProps = {
  hidden: false,
  primary: false
};

export default Button;
