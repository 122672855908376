import React from "react";
import "./styles.css";

export const Loader = ({ color, size }) => {
  return (
    <svg
      className="spinner"
      style={{ width: size, height: size }}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="circle"
        stroke={color}
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>
  );
};

export default Loader;
