import React, { PureComponent } from "react";
import { getI18n } from "react-i18next";
import { RichText } from "prismic-reactjs";

import * as PrismicAPI from "../../core/services/PrismicAPI";
import { linkResolver } from "../../core/utils/htmlSerializer";

import "./styles.css";
import { withJob } from "react-jobs";

class Legal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      content: null,
      title: null,
      latestRevision: null
    };
  }

  componentWillMount() {
    this.getData();
    getI18n().on("languageChanged", lng => this.getData(lng));
  }

  componentWillUnmount() {
    getI18n().off("languageChanged", lng => this.getData(lng));
  }

  getData(lng) {
    let language = lng;
    const i18n = getI18n();
    const languages = {
      en: "en-gb",
      fr: "fr-fr",
      nl: "nl-be"
    };
    language = languages[i18n.language] || "en-gb";

    if (lng != null) {
      language = languages[lng] || "en-gb";
    }

    PrismicAPI.getLegalPage(language)
      .then(response => {
        const payload = response.results;
        const content = payload[0].data.page_content;
        const title = payload[0].data.page_title[0].text;
        const latestRevision = payload[0].data.latest_revision;
        this.setState({ content, title, latestRevision });
      })
      .catch(e => console.error);
  }

  render() {
    const { jobResult } = this.props;
    const [content, title, latestRevision] = jobResult;

    return (
      <article
        style={{ width: "calc(100% - 48px)", margin: "0 24px" }}
        className={"Contact__container"}
      >
        {title != null && <h1 className="Content__title">{title}</h1>}
        {latestRevision != null && <h6>Latest revision: {latestRevision}</h6>}
        {content != null && RichText.render(content, linkResolver)}
      </article>
    );
  }
}

const Loader = () => (
  <svg
    className="spinner"
    width="65px"
    height="65px"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
    style={{ stroke: "#5677fc" }}
  >
    <circle
      className="circle"
      fill="none"
      strokeWidth="6"
      strokeLinecap="round"
      cx="33"
      cy="33"
      r="30"
    />
  </svg>
);

export default withJob({
  LoadingComponent: Loader,
  serverMode: "resolve",
  work: async () => {
    const lng = getI18n().language.substr(0, 2);
    const lang = {
      en: "en-gb",
      fr: "fr-fr",
      nl: "nl-be"
    }[lng];

    const legalPage = await PrismicAPI.getLegalPage(lang);
    const payload = legalPage.results;
    const content = payload[0].data.page_content;
    const title = payload[0].data.page_title[0].text;
    const latestRevision = payload[0].data.latest_revision;
    return [content, title, latestRevision];
  }
})(Legal);
