import React from "react";
import { Switch, Redirect } from "react-router-dom";

import CommonLayout from "./components/Common/Layout";
import InstallView from "./views/Install";
import VerifyView from "./views/Verify";
import HomeView from "./views/Home";
import DocumentationView from "./views/Documentation";
import ContactView from "./views/Contact";
import LegalView from "./views/Legal";
// import NotFoundView from "./views/NotFound";

const Routes = () => (
  <Switch>
    {/* To keep compatibility with Webstore. */}
    <Redirect from="/documentation" to="/docs" />
    <CommonLayout exact path="/" component={HomeView} />
    <CommonLayout
      singleScreen={true}
      path="/install/:code"
      component={InstallView}
    />
    <CommonLayout
      singleScreen={true}
      path="/verify"
      component={VerifyView}
    />
    <CommonLayout
      singleScreen={false}
      path="/docs/:pageSlug?"
      component={DocumentationView}
    />
    <CommonLayout
      singleScreen={false}
      path="/contact"
      component={ContactView}
    />
    <CommonLayout
      singleScreen={false}
      path="/legal"
      component={LegalView}
    />
  </Switch>
);

export default Routes;
