import React, { Component } from "react";
import logo from "../../../images/logo-horizontal.svg";
import "./styles.css";

export class Logo extends Component {
  render() {
    const { isMobileDevice, ...rest } = this.props;
    return (
      <img
        src={logo}
        className={
          isMobileDevice != null && isMobileDevice ? "Logo Logo--small" : "Logo"
        }
        alt="PhoneCompanion logo"
        {...rest}
      />
    );
  }
}

export default Logo;
