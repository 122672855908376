import React, { PureComponent } from "react";
import { getI18n } from "react-i18next";
import { RichText } from "prismic-reactjs";

import * as PrismicAPI from "../../core/services/PrismicAPI";
import { linkResolver } from "../../core/utils/htmlSerializer";

import "./styles.css";
import { withJob } from "react-jobs";

class Contact extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      content: null
    };
  }

  render() {
    const { jobResult } = this.props;
    const [content] = jobResult;

    return (
      <article
        style={{ width: "calc(100% - 48px)", margin: "0 24px" }}
        className={"Contact__container"}
      >
        {content != null ? RichText.render(content, linkResolver) : <Loader />}
      </article>
    );
  }
}

const Loader = () => (
  <svg
    className="spinner"
    width="65px"
    height="65px"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
    style={{ stroke: "#5677fc" }}
  >
    <circle
      className="circle"
      fill="none"
      strokeWidth="6"
      strokeLinecap="round"
      cx="33"
      cy="33"
      r="30"
    />
  </svg>
);

export default withJob({
  LoadingComponent: Loader,
  serverMode: "resolve",
  work: async () => {
    const lng = getI18n().language.substr(0, 2);
    const lang = {
      en: "en-gb",
      fr: "fr-fr",
      nl: "nl-be"
    }[lng];

    const contactPage = await PrismicAPI.getContactPage(lang);
    const contactContent = contactPage.results[0].data.content;
    return [contactContent];
  }
})(Contact);
