import React, { PureComponent, Fragment } from "react";
import { withTranslation, getI18n } from "react-i18next";

import * as PrismicAPI from "../../core/services/PrismicAPI";
import { Feature, Screenshot, Button } from "../../components";

import "./styles.css";
import dashboardFR from "../../images/screenshots/fr/dashboard.png";
import settingsFR from "../../images/screenshots/fr/settings.png";
import dashboardEN from "../../images/screenshots/en/dashboard.png";
import settingsEN from "../../images/screenshots/en/settings.png";
import dashboardNL from "../../images/screenshots/nl/dashboard.png";
import settingsNL from "../../images/screenshots/nl/settings.png";
import { withJob } from "react-jobs";

const dashboardScreens = {
  fr: dashboardFR,
  en: dashboardEN,
  nl: dashboardNL
};

const settingsScreens = {
  fr: settingsFR,
  en: settingsEN,
  nl: settingsNL
};

class Home extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      title: null,
      tagline: null,
      features: [],
      lang: "en"
    };
  }

  render() {
    const { t, isMobileDevice, jobResult } = this.props;
    const [title, tagline, features] = jobResult;

    const { lang } = this.state;
    const screenDashboard = dashboardScreens[lang.substr(0, 2)];
    const screenSettings = settingsScreens[lang.substr(0, 2)];

    return (
      <Fragment>
        <div className={"Landing__hero"}>
          <h1
            className={isMobileDevice ? "Heading Heading--mobile" : "Heading"}
          >
            {title}
          </h1>
          <h2
            className={isMobileDevice ? "Tagline Tagline--mobile" : "Tagline"}
          >
            {tagline}
          </h2>
          <Button
            primary={true}
            text={t("home:page.cta")}
            url={"/docs"}
            onClick={e => {
              e.preventDefault();
              this.props.history.push("/docs");
            }}
          />
        </div>
        <div
          className={
            isMobileDevice
              ? "Features__container Features__container--mobile"
              : "Features__container"
          }
        >
          {features.map(feature => (
            <Feature
              key={feature.feature_name[0].text}
              iconComponent={() => (
                <img
                  src={feature.feature_icon.url}
                  alt={feature.feature_icon.alt}
                  style={{ width: 48, height: 48 }}
                />
              )}
              name={feature.feature_name[0].text}
              description={feature.feature_description[0].text}
            />
          ))}
        </div>
        {isMobileDevice === false && (
          <section
            className={
              isMobileDevice
                ? "Screenshots__container Screenshots__container--mobile"
                : "Screenshots__container"
            }
          >
            <Screenshot
              url={screenSettings}
              alt="Screenshot of the PhoneCompanion Extension"
            />
            <Screenshot
              url={screenDashboard}
              alt="Screenshot of the PhoneCompanion Extension"
              bigger={true}
            />
            <Screenshot
              url={screenSettings}
              alt="Screenshot of the PhoneCompanion Extension"
            />
          </section>
        )}
      </Fragment>
    );
  }
}

const Loader = () => (
  <svg
    className="spinner"
    width="65px"
    height="65px"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
    style={{ stroke: "#5677fc" }}
  >
    <circle
      className="circle"
      fill="none"
      strokeWidth="6"
      strokeLinecap="round"
      cx="33"
      cy="33"
      r="30"
    />
  </svg>
);

export default withJob({
  LoadingComponent: Loader,
  serverMode: "resolve",
  work: async () => {
    const lng = getI18n().language.substr(0, 2);
    const lang = {
      en: "en-gb",
      fr: "fr-fr",
      nl: "nl-be"
    }[lng];

    const homePage = await PrismicAPI.getHomepage(lang);
    const payload = homePage.results;
    const title = payload[0].data.heading[0].text;
    const tagline = payload[0].data.tagline[0].text;
    const features = payload[0].data.features;
    return [title, tagline, features];
  }
})(withTranslation("home")(Home));
