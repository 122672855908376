import React, { PureComponent } from "react";
import { Route, Link } from "react-router-dom";
import { withTranslation, getI18n } from "react-i18next";
import { withJob } from "react-jobs";

import * as PrismicAPI from "../../../core/services/PrismicAPI";
import { Navigation } from "../../";

import "./styles.css";

const MOBILE_WIDTH = 768;

export class Layout extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      lang: "en",
      isMobileDevice: false,
      drawerOpen: false,
      showBurger: false,
      availableLanguages: ["en", "fr", "nl"],
      footerCopyrightText: '',
      footerLinks: [],
    };

    this.changeLanguage = this.changeLanguage.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.setDrawerIconVisible = this.setDrawerIconVisible.bind(this);
    this.setAvailableLanguages = this.setAvailableLanguages.bind(this);
    this._handleResponsive = this._handleResponsive.bind(this);
  }

  componentDidMount() {
    this.fetchFooter();
    const i18n = getI18n();
    this.setState({ lang: i18n.language.substr(0, 2) })
    i18n.on("languageChanged", () => {
      this.setState({ lang: i18n.language.substr(0, 2) })
      this.fetchFooter();
    });

    this.mq = window.matchMedia(`(max-width: ${MOBILE_WIDTH}px)`);
    this.mq.addListener(this._handleResponsive);
    this._handleResponsive(this.mq);
  }

  componentWillUnmount() {
    this.mq && this.mq.removeListener(this.mq);
  }

  _handleResponsive(mq) {
    this.setState({ isMobileDevice: mq.matches });
  }

  toggleDrawer() {
    if (this.state.isMobileDevice === false) {
      return;
    }

    const { drawerOpen } = this.state;
    this.setState({ drawerOpen: !drawerOpen });
  }

  setDrawerIconVisible(status) {
    if (this.state.isMobileDevice === false) {
      return;
    }

    this.setState({ showBurger: status });
  }

  setAvailableLanguages(availableLanguages) {
    if (this.state.availableLanguages === availableLanguages) {
      return;
    }

    this.setState({ availableLanguages });
  }

  async changeLanguage(lang) {
    const i18n = getI18n();
    await i18n.changeLanguage(lang);
    this.setState({ lang: i18n.language.substr(0, 2) });
  }

  async fetchFooter() {
    const lng = getI18n().language.substr(0, 2);
    const lang = {
      en: "en-gb",
      fr: "fr-fr",
      nl: "nl-be"
    }[lng];

    const result = await PrismicAPI.getFooter(lang)
    const {
      data: {
        copyright_text: {
          0: { text: footerCopyrightText }
        },
        links: footerLinks
      }
    } = result.results[0];
    this.setState({footerCopyrightText, footerLinks});
  }

  render() {
    const {
      t,
      component: Component,
      singleScreen,
      jobResult,
      ...props
    } = this.props;

    const {
      lang,
      isMobileDevice,
      drawerOpen,
      showBurger,
      availableLanguages,
      footerCopyrightText,
      footerLinks,
    } = this.state;

    const containerClassesNames = singleScreen
      ? "container container__full-height"
      : "container";

    const contentClassesNames = singleScreen
      ? "content content__full-height"
      : "content";

    return (
      <Route
        key={lang}
        {...props}
        render={matchProps => (
          <div className={containerClassesNames}>
            <div className="wrapper">
              <Navigation
                t={t}
                lang={lang}
                changeLanguage={this.changeLanguage}
                isMobileDevice={isMobileDevice}
                toggleDrawer={this.toggleDrawer}
                showBurger={showBurger}
                availableLanguages={availableLanguages}
                {...matchProps}
              />
              <div className={contentClassesNames}>
                <Component
                  t={t}
                  drawerOpen={drawerOpen}
                  toggleDrawer={this.toggleDrawer}
                  setDrawerIconVisible={this.setDrawerIconVisible}
                  isMobileDevice={isMobileDevice}
                  setAvailableLanguages={this.setAvailableLanguages}
                  availableLanguages={availableLanguages}
                  {...matchProps}
                />
              </div>
           <footer className="Footer__container">
                {String(footerCopyrightText).replace(
                    "%year%",
                    new Date().getFullYear()
                  )}
                {footerLinks.map(item => {
                    const {
                      link: { url, slug, link_type: linkType },
                      link_text: {
                        0: { text: linkText }
                      }
                    } = item;

                    const links = {
                      document: (
                        <Link key={linkText} to={`/docs/${slug}`}>
                          {linkText}
                        </Link>
                      ),
                      web: (
                        <a key={linkText} href={url}>
                          {linkText}
                        </a>
                      )
                    };

                    return links[linkType.toLowerCase()];
                  })}
              </footer>
            </div>
          </div>
        )}
      />
    );
  }
}

export default (withTranslation("commons")(Layout));
