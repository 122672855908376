import React, { Component, Fragment } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

import * as AuthService from "../core/services/Auth";

import Loader from "../components/Common/Icons/Loader";
import Check from "../components/Common/Icons/Check";


const { REACT_APP_API_HOST } = process.env


export class Verify extends Component {
  constructor(props) {
    super(props);

    this.state = {
      provisioned: false,
      error: null,
      slow: false,
    };
  }

  componentDidMount () {
    this.slowTimeoutId = setTimeout(() => {
      // Only show the help UI (text code) if it takes more than 20s
      this.setState({ slow: true });
    }, 20000);

    this.connectPhoneCompanion()
      .then(result => {
        this.setState({ provisioned: true })
      })
      .catch(e => {
        console.error(e)
        this.setState({ error: e })
      })
      .then(() => {
        this.slowTimeoutId && clearTimeout(this.slowTimeoutId)
      })
  }

  componentWillUnmount () {
    this.slowTimeoutId && clearTimeout(this.slowTimeoutId);
  }

  connectPhoneCompanion() {
    console.log('test with code...')
    return AuthService.retrieveCredentials()
      .then(code => this.testWebSocket({code}))
      .catch(e => {
        console.log('test with legacy code...')
        // legacy credentials are deprecated
        // NOTE: once this one is executed, cookies are set and using the new backend will always use cookies
        // Except by clearing cookies on the host, there is no way back to use the otc param to connect websocket
        // As of this code is written, all new installed phone companion will not be able to use callebaut anymore
        return AuthService.legacy_retrieveCredentials()
          .then(code => this.testWebSocket({otc: code}))
          .catch(e => {
            if (typeof chrome === 'undefined') {
              console.log('test without code...')
              return this.testWebSocket()
            }
          })
          .catch(e => {
            throw {type: 'ERROR', message: e.reason}
          })
      })
      .then(() => AuthService.clearCredentials())
      .then(() => {
        console.log('setup phone companion...')
        if (typeof chrome === 'undefined') {
          console.log('setup via window message...')
          window.postMessage({
            type: "FROM_PAGE_TO_CONTENT_SCRIPT",
            payload: {
              action: 'ACTION_AUTH_SETUP', data: {}
            }
          }, "*")
          return
        }
        console.log('setup via extension message...')
        return this.sendMessageToExtension({action: 'ACTION_AUTH_SETUP', data: {}})
      })
      .then(() => {
        console.log('done')
      })
  }

  testWebSocket(qs = {}) {
    const url = `wss://${REACT_APP_API_HOST}/api/ws?${new URLSearchParams(qs).toString()}`
    return new Promise((resolve, reject) => {
      const webSocket = new WebSocket(url)
      webSocket.onerror = (err) => {
        reject(err)
      }
      webSocket.onclose = (event) => {
        reject(event)
      }
      webSocket.onmessage = (event) => {
        const data = JSON.parse(event.data)
        const { id, method } = data
        if (method && method === 'configure') {
          const body = {id, result: true}
          webSocket.send(JSON.stringify(body))
          webSocket.close()
          resolve()
        }
      }
    })
  }

  sendMessageToExtension(message) {
    const isFirefox = typeof InstallTrigger !== 'undefined'
    const extensionID = isFirefox ? 'extension@phonecompanion.cloud' : 'incncndkecbflcedgfhkiepnalopkkjd'

    return new Promise((resolve, reject) => {
      chrome.runtime.sendMessage(extensionID, message, response => {
        if (response && (response.stack || response.type === 'ERROR') && response.message) {
          reject(response)
          return
        }
        resolve(response)
      })
    })
  }

  render () {
    const { t } = this.props;
    const { provisioned, slow, error } = this.state;

    return (
      <div key={this.state.lang || "en"}>
        {provisioned ? (
          <h1 className="Heading">
            <Check key={"check"} color="#8bc34a" size={40} />
            <span style={{ marginLeft: 20 }}>
              {t("verify:page.ready.title")}
            </span>
          </h1>
        ) : (
            <h1 className="Heading">
              <Loader
                key={"loader"}
                color="#1f82e4"
                size={40}
                style={{ display: "inline-flex" }}
              />
              <span style={{ marginLeft: 20 }}>
                {t("verify:page.inProgress.title")}
              </span>
            </h1>
          )}
        <h2 className="Tagline" style={{ marginBottom: 12, maxWidth: '100%' }}>
          {provisioned
            ? t("verify:page.ready.tagline")
            : t("verify:page.inProgress.tagline")}
        </h2>
        {provisioned && (
          <h2 className="Tagline" style={{ margin: 0, maxWidth: '100%' }}>
            <Trans i18nKey={"verify:page.ready.documentationNotice"}>
              For optimal utilization, please refer to the <Link to={`/docs/`}>documentation</Link>.
            </Trans>
          </h2>
        )}
        {slow && !provisioned ? (
          <Fragment>
            <h3 className="config-issues">
              {t("verify:page.configuration.delayed")}
            </h3>
          </Fragment>
        ) : null}
        {error ? (
          <Fragment>
            <h3 className="config-issues">
              {error.message}
            </h3>
          </Fragment>
        ) : null}
      </div>
    );
  }
}

export default withTranslation("verify")(Verify);
