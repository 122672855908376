import React from "react";

export const Check = ({ color, size }) => {
  return (
    <svg style={{ width: size, height: size }} viewBox={`0 0 24 24`}>
      <path
        fill={color}
        color={"none"}
        d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
      />
    </svg>
  );
};

export default Check;
