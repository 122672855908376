import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { I18nextProvider } from "react-i18next";

import Routes from "./routes";
import i18n from "./i18n";

import { unregister } from "./registerServiceWorker";
import "./index.css";

export const App = () => (
  <Suspense fallback={(
    <div></div>
  )}>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </I18nextProvider>
  </Suspense>
);
ReactDOM.render(<App />, document.getElementById("root"));

unregister();
