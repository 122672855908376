import React, { Component } from "react";

import { Logo } from "../../";
import "./styles.css";

export class Navigation extends Component {
  renderLanguages() {
    const { lang, availableLanguages, changeLanguage } = this.props;

    return (
      <div className="languages">
        {(availableLanguages.indexOf("nl") !== -1 || lang === "nl") && (
          <a
            onClick={e => {
              e.preventDefault();
              changeLanguage("nl");
            }}
            className={lang === "nl" ? "active" : null}
            title="Nederlands"
            tabIndex={0}
          >
            NL
          </a>
        )}
        {(availableLanguages.indexOf("fr") !== -1 || lang === "fr") && (
          <a
            onClick={e => {
              e.preventDefault();
              changeLanguage("fr");
            }}
            className={lang === "fr" ? "active" : null}
            title="Français"
            tabIndex={0}
          >
            FR
          </a>
        )}
        {(availableLanguages.indexOf("en") !== -1 || lang === "en") && (
          <a
            onClick={e => {
              e.preventDefault();
              changeLanguage("en");
            }}
            className={lang === "en" ? "active" : null}
            title="English"
            tabIndex={0}
          >
            EN
          </a>
        )}
      </div>
    );
  }

  render() {
    const {
      t,
      isMobileDevice,
      match: { path, isExact },
      toggleDrawer,
      showBurger
    } = this.props;

    return (
      <header className={isMobileDevice ? "Header Header--mobile" : "Header"}>
        <div
          className={
            isMobileDevice
              ? "Logo__wrapper Logo__wrapper--mobile"
              : "Logo__wrapper"
          }
        >
          <div className="mobileMenuWrapper">
            {isMobileDevice && showBurger && (
              <div className="BurgerMenu" onClick={toggleDrawer}>
                <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                  <path
                    fill="#000000"
                    d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
                  />
                </svg>
              </div>
            )}
            <Logo
              className={
                isMobileDevice
                  ? showBurger
                    ? "Logo--mobile Logo--burger"
                    : "Logo--mobile"
                  : "Logo"
              }
              onClick={() => this.props.history.push("/")}
              isMobileDevice={isMobileDevice}
            />
          </div>
          {isMobileDevice && this.renderLanguages()}
        </div>
        <nav
          className={
            isMobileDevice ? "Navigator Navigator--mobile" : "Navigator"
          }
        >
          {isMobileDevice === false && this.renderLanguages()}
          <div className="menu">
            {/*<a
              onClick={e => {
                e.preventDefault();
                this.props.history.push("/");
              }}
              href={"/"}
              className={path === "/" && isExact ? "active" : null}
              title={t("commons:menu.home")}
              tabIndex={0}
            >
              {t("commons:menu.home")}
            </a>*/}
            <a
              onClick={e => {
                e.preventDefault();
                this.props.history.push("/docs");
              }}
              href={"/docs"}
              className={path.indexOf("docs") !== -1 ? "active" : null}
              title={t("commons:menu.docs")}
              tabIndex={0}
            >
              {t("commons:menu.docs")}
            </a>
            <a
              onClick={e => {
                e.preventDefault();
                this.props.history.push("/contact");
              }}
              href={"/contact"}
              className={path === "/contact" && isExact ? "active" : null}
              title={t("commons:menu.contact")}
              tabIndex={0}
            >
              {t("commons:menu.contact")}
            </a>
          </div>
        </nav>
      </header>
    );
  }
}

export default Navigation;
