import React, { Component } from "react";

import "./styles.css";

export class Feature extends Component {
  render() {
    const { iconComponent: Icon, name, description, ...rest } = this.props;
    return (
      <div className="feature" {...rest}>
        <div className="feature--icon">
          <Icon />
        </div>
        <div className="feature--name">{name}</div>
        <div className="feature--description">{description}</div>
      </div>
    );
  }
}

export default Feature;
