import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import bowser from "bowser";

import * as AuthService from "../core/services/Auth";
import { Button } from "../components";

class Install extends Component {
  constructor(props) {
    super(props);

    this.state = {
      browserName: null,
      hasStoredCode: false
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { code }
      }
    } = this.props;

    const browser = bowser.getParser(window.navigator.userAgent);
    const urlParams = new URLSearchParams(window.location.search);

    this.setState({ browserName: browser.getBrowserName() });

    if (this.state.browserName === "") {
      const isFirefox = window.navigator.userAgent.indexOf("Firefox") !== -1;
      const isChrome = window.navigator.userAgent.indexOf("Chrome") !== -1;

      this.setState({
        browserName: isFirefox ? "Firefox" : isChrome ? "Chrome" : null
      });
    }

    const codeRegex = /^([0-9a-z-]+)-([a-z]+)-([a-z]+)-([a-z]+)-([0-9a-f]{6})$/gi;
    const matches = codeRegex.exec(code);

    if (Array.isArray(matches) === true && matches.length > 1) {
      const accountId = matches[1];
      const accountRealm = urlParams.get("realm") || "allocloud.com";
      AuthService.storeCredentials(code, accountId, accountRealm)
        .then(() => this.setState({ hasStoredCode: true, code }))
        .catch(() => this.setState({ hasStoredCode: false }));

      return { code };
    }

    AuthService.storeCredentials(code)
      .then(() => this.setState({ hasStoredCode: true, code }))
      .catch(() => this.setState({ hasStoredCode: false }));

    return { code };
  }

  render() {
    const { t } = this.props;
    const { browserName } = this.state;

    return (
      <Fragment>
        <h1 className="Heading">{t("install:page.title")}</h1>
        <h2 className="Tagline">{t("install:page.tagline")}</h2>
        <div className="buttonsWrapper">
          {browserName === "Chrome" ||
          browserName === "Vivaldi" ||
          browserName === "Chromium" ? (
            <Button
              text={t("install:buttons.chrome")}
              url="https://chrome.google.com/webstore/detail/phonecompanion/incncndkecbflcedgfhkiepnalopkkjd"
            />
          ) : browserName === "Firefox" ? (
            <Button
              text={t("install:buttons.firefox")}
              url="https://phonecompanion.cloud/static/downloads/phonecompanion-latest-an+fx.xpi"
              openInNewTab={false}
            />
          ) : (
            <span>
              {t("install:unsupportedBrowser")} + {browserName}
            </span>
          )}
        </div>
      </Fragment>
    );
  }
}

export default withTranslation("install")(Install);
