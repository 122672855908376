const STORAGE_KEY = "phone-companion:auth";

export const storeCredentials = (code, accountId = null, accountRealm = null) => {
  if ("localStorage" in window === false) {
    return Promise.reject(
      new Error(
        "Cannot access localStorage, make sure a plugin is not messing with it."
      )
    );
  }

  localStorage.setItem(`${STORAGE_KEY}:code`, code);

  if (accountId) {
    localStorage.setItem(`${STORAGE_KEY}:accountId`, accountId);
  }

  if (accountRealm) {
    localStorage.setItem(`${STORAGE_KEY}:accountRealm`, accountRealm);
  }

  return Promise.resolve();
};

export const legacy_retrieveCredentials = () => {
  if ("localStorage" in window === false) {
    return Promise.reject(
      new Error(
        "Cannot access localStorage, make sure a plugin is not messing with it."
      )
    );
  }

  const code = localStorage.getItem(`${STORAGE_KEY}:code`);
  const accountId = localStorage.getItem(`${STORAGE_KEY}:accountId`);
  const accountRealm = localStorage.getItem(`${STORAGE_KEY}:accountRealm`);

  if (!code || !accountId || !accountRealm) return Promise.reject()

  return Promise.resolve(code, accountId, accountRealm);
};

export const retrieveCredentials = () => {
  if ("localStorage" in window === false) {
    return Promise.reject(
      new Error(
        "Cannot access localStorage, make sure a plugin is not messing with it."
      )
    );
  }

  const code = localStorage.getItem(`${STORAGE_KEY}:code`);

  if (!code) return Promise.reject()
  if (code.indexOf(':') === -1) return Promise.reject()

  return Promise.resolve(code);
};

export const clearCredentials = () => {
  if ("localStorage" in window === false) {
    return Promise.reject(
      new Error(
        "Cannot access localStorage, make sure a plugin is not messing with it."
      )
    );
  }

  localStorage.removeItem(`${STORAGE_KEY}:code`);
  localStorage.removeItem(`${STORAGE_KEY}:accountId`);
  localStorage.removeItem(`${STORAGE_KEY}:accountRealm`);

  return Promise.resolve();
};
