import React, { Component } from "react";

import "./styles.css";

export class Screenshot extends Component {
  render() {
    const { url, alt, bigger } = this.props;
    const classes = bigger ? "screenshot screenshotUpper" : "screenshot";

    return (
      <div className={classes}>
        <img className="screenshotImage" src={url} alt={alt} />
      </div>
    );
  }
}

export default Screenshot;
