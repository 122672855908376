import React, { Component, Fragment } from "react";
import { getI18n } from "react-i18next";
import { RichText } from "prismic-reactjs";
import { withJob } from "react-jobs";

import * as PrismicAPI from "../../core/services/PrismicAPI";
import { linkResolver } from "../../core/utils/htmlSerializer";

import "./styles.css";

class Documentation extends Component {
  componentDidMount() {
    this.props.setDrawerIconVisible(true);
  }

  componentWillUnmount() {
    this.props.setDrawerIconVisible(false);
    this.props.setAvailableLanguages(["en", "fr", "nl"]);
    getI18n().off("languageChanged");
  }

  componentDidUpdate() {
    if (this.props.isMobileDevice === true) {
      this.props.setDrawerIconVisible(true);
    }
  }

  __renderMenu(label, menu, pages, defaultPage) {
    if (menu.length <= 0) return null;

    const {
      drawerOpen,
      toggleDrawer,
      match: {
        params: { pageSlug }
      }
    } = this.props;

    const getPageDetails = item => {
      const { id, uid: slug } = item;
      const page = pages.find(page => page.id === id);
      if (typeof page === "undefined" || page == null) {
        return null;
      }

      const { page_title: pageTitle, menu_title: menuTitle } = page.data;
      return {
        pageTitle: pageTitle[0].text,
        menuTitle: menuTitle[0].text,
        pageHref: `/docs/${slug}`
      };
    };

    const isActive = item => {
      return (
        item.uid === pageSlug ||
        (pageSlug == null && defaultPage.slug === item.uid)
      );
    };

    return (
      <React.Fragment>
        <p className="Menu__category">{label}</p>
        {menu.map(item => {
          const { page } = item;
          const result = getPageDetails(page);

          if (result == null) return null;
          const { pageTitle, menuTitle, pageHref } = result;

          return (
            <a
              key={page.id}
              className={
                isActive(page) ? "Menu__page Menu__page--active" : "Menu__page"
              }
              onClick={e => {
                e.preventDefault();
                this.props.history.push(pageHref);
                drawerOpen && toggleDrawer();
              }}
              href={pageHref}
              title={pageTitle}
              tabIndex={0}
            >
              {menuTitle}
            </a>
          );
        })}
      </React.Fragment>
    );
  }

  __renderContent(slug, pages, defaultPage) {
    let page;
    if (slug == null) {
      const { id } = defaultPage;
      page = pages.find(page => page.id === id);
    } else {
      page = pages.find(page => page.uid.includes(slug));
    }

    if (page == null || page.data == null) {
      return (
        <Fragment>
          <h1>The requested page was not found.</h1>
        </Fragment>
      );
    }

    const { page_title: pageTitle, page_content: pageContent } = page.data;
    return (
      <Fragment>
        <h1 className="Content__title">{pageTitle[0].text}</h1>
        {RichText.render(pageContent, linkResolver)}
      </Fragment>
    );
  }

  render() {
    const {
      isMobileDevice,
      drawerOpen,
      toggleDrawer,
      jobResult,
      match: {
        params: { pageSlug }
      }
    } = this.props;

    const [pages, menu] = jobResult;
    const { results: docPages } = pages;
    const {
      admin_doc_menu: adminDoc,
      user_doc_menu: userDoc,
      default_doc_page: defaultPage
    } = menu.results[0].data;

    return (
      <div
        className={
          isMobileDevice
            ? "Documentation__container Documentation__container--mobile"
            : "Documentation__container"
        }
      >
        {isMobileDevice && (
          <div
            className={drawerOpen ? "Scrim Scrim--shown" : "Scrim"}
            onClick={toggleDrawer}
          />
        )}
        <aside
          className={
            drawerOpen
              ? "Menu__container Menu__container--shown"
              : "Menu__container"
          }
        >
          {this.__renderMenu("User docs", userDoc, docPages, defaultPage)}
          {this.__renderMenu("Admin docs", adminDoc, docPages, defaultPage)}
        </aside>
        <article
          className={
            document == null
              ? "Content__container--empty"
              : "Content__container"
          }
        >
          {this.__renderContent(pageSlug, docPages, defaultPage)}
        </article>
      </div>
    );
  }
}

const Loader = () => (
  <svg
    className="spinner"
    width="65px"
    height="65px"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
    style={{ stroke: "#5677fc" }}
  >
    <circle
      className="circle"
      fill="none"
      strokeWidth="6"
      strokeLinecap="round"
      cx="33"
      cy="33"
      r="30"
    />
  </svg>
);

export default withJob({
  LoadingComponent: Loader,
  serverMode: "resolve",
  work: async () => {
    const lng = getI18n().language.substr(0, 2);
    const lang = {
      en: "en-gb",
      fr: "fr-fr",
      nl: "nl-be"
    }[lng];

    const docPages = await PrismicAPI.getDocumentationPages(lang);
    const docMenu = await PrismicAPI.getDocumentationMenu(lang);

    return [docPages, docMenu];
  }
})(Documentation);
